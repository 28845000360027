export const categories = [
  'All',
  'Kitchen Benchtops',
  'Monuments',
  'Staircases',
  'Bathrooms',
  'Vanities',
  'Shop Fronts',
  'Walls',
  'Fireplaces',
  'Floors'
];

export const links = {
  facebook: 'https://www.facebook.com/Edelweiss-stone-495239060955229/',
  instagram: 'https://www.instagram.com/edelweissstone/',
  mailto: 'mailto:info@edelweissstone.com.au',
  tel: 'tel:(02) 0431 703 388'
};

// Carousel Images (in Projects)
const caro1 = require('../assets/images/carousel/caro1.jpg');
const caro2 = require('../assets/images/carousel/caro2.jpg');
const caro3 = require('../assets/images/carousel/caro3.jpg');
const caro4 = require('../assets/images/carousel/caro4.jpg');
const caro5 = require('../assets/images/carousel/caro5.jpg');
const caro6 = require('../assets/images/carousel/caro6.jpg');
const caro7 = require('../assets/images/carousel/caro7.jpg');

// Carousel Items (in Projects)
export const caroItems = [
  {
    description: 'Renovation',
    image: caro1
  },
  {
    description: 'Fireplace',
    image: caro2
  },
  {
    description: 'Kitchen Benchtop',
    image: caro3
  },
  {
    description: 'Bathroom',
    image: caro4
  },
  {
    description: 'Kitchen Benchtop',
    image: caro5
  },
  {
    description: 'Kitchen Benchtop',
    image: caro6
  },
  {
    description: 'Kitchen Benchtop',
    image: caro7
  }
];
