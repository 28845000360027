import React from 'react';

const RequestQuote = () => {
  return (
    <div>
      <h1>Request a quote</h1>
    </div>
  );
};

export default RequestQuote;
